import React from 'react';
import { graphql, Link } from 'gatsby';
import Sign from '../components/sign'
import Layout from "../components/layout"
import CompatibilidadeAmorosaBlock from "../components/compatibilidade-amorosa-block"
import {
  Section,
  Column,
  Columns,
  Content
} from "bloomer"  
import SEO from '../components/seo';

export default ({ data }) => {
  // const { currentPage, numPages } = pageContext;
  return (
    <Layout>
      <SEO title="Compatibilidade Amorosa - Estrelaguia Guia" />
      <Content>
        <h1>Compatibilidade Amorosa</h1>
        <p>Seu amor estava escrito nas estrelas ou é um verdadeiro desastre astral? Descubra o que os astros tem a dizer sobre seu relacionamento amoroso. Selecione o seu signo e depois o de seu parceiro/a e clique em Combinar. Saiba quais são os pontos fortes e os fracos dessa relação e faça com que dê certo.</p>
        <p>Descubra o que os astros têm a dizer sobre seu relacionamento!</p>
      </Content>
      <CompatibilidadeAmorosaBlock />
      {/* <Columns isMultiline isMobile className="column">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Column isSize='1/3'>
          <Link to={node.fields.slug}>
            <Sign icon={node.frontmatter.mname} title={node.frontmatter.title}></Sign>
            <h1>{node.frontmatter.title}</h1>
          </Link>
          </Column>
      ))}
      </Columns> */}
    </Layout> 
  );
};

export const query = graphql`
  query compatibilidadeAmorosaListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "compatibilidade-amorosa" } }
      }
      sort: { fields: [frontmatter___name], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            mname            
          }
          excerpt
        }
      }
    }
  }
`;